import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {DataSourceDto} from "../../../models/dataSourceDto";
import {CoreBackendServices} from "../../../services/core-backend.service";
import {MessageService, ObjectCloner} from "@ianitor/shared-services";
import {GraphQLCloneIgnoredProperties} from "@ianitor/osp-services";

@Component({
  selector: 'app-data-source-details',
  templateUrl: './data-source-details.component.html',
  styleUrls: ['./data-source-details.component.scss']
})
export class DataSourceDetailsComponent implements OnInit {

  private readonly dataSourceId: string;
  private readonly isAttach: boolean;
  public loading: boolean;

  public readonly dataSourceForm: FormGroup;

  private dataSourceDto: DataSourceDto;

  constructor(route: ActivatedRoute, private fb: FormBuilder, private coreBackendServices: CoreBackendServices, private messageService: MessageService) {
    this.dataSourceId = route.snapshot.paramMap.get('dataSource');
    this.isAttach = route.snapshot.url[1].path === "attach";

    this.dataSourceForm = this.fb.group({
      dataSource: ['', Validators.required],
      database: ['', Validators.required]
    });
  }

  ngOnInit(): void {
    if (this.dataSourceId) {
      this.coreBackendServices.getDataSourceDetails(this.dataSourceId).subscribe(value => {
        this.dataSourceDto = value;

        this.dataSourceForm.setValue({
          dataSource: value.dataSource,
          database: value.database
        });
        this.loading = false;

      })
    } else {
      this.dataSourceDto = <DataSourceDto>{};

      this.dataSourceForm.setValue({dataSource: null, database: null});
      this.loading = false;
    }
  }

  save() {

    this.dataSourceForm.disable();
    this.dataSourceForm.updateValueAndValidity();

    const dataSourceDto = ObjectCloner.cloneObject<DataSourceDto, any>(this.dataSourceForm.value, [...GraphQLCloneIgnoredProperties]);


    if (this.isAttach) {

      this.coreBackendServices.attachDataSource(dataSourceDto).subscribe(_ => {
        this.messageService.showInformation(`Data Source '${dataSourceDto.dataSource}' has been attached.`);

        this.dataSourceForm.reset();
        this.dataSourceForm.enable();

      }, error => {
        this.dataSourceForm.enable();
      })

    } else {
      this.coreBackendServices.createDataSource(dataSourceDto).subscribe(_ => {
        this.messageService.showInformation(`Data Source '${dataSourceDto.dataSource}' has been created.`);

        this.dataSourceForm.reset();
        this.dataSourceForm.enable();

      }, error => {
        this.dataSourceForm.enable();
      })
    }
  }
}
