<h2 mat-dialog-title>Details to entity '{{ckId}}'</h2>

<div *ngIf="loading">
  <mat-progress-bar class="w-100" mode="query"></mat-progress-bar>
</div>

<div *ngIf="constructionKitTypeDetail && ownerForm && !loading">
  <div [formGroup]="ownerForm">
    <div class="my-2" align="end">
      <!--      <button mat-raised-button color="primary" class="mx-1" (click)="save()" [disabled]="ownerForm?.invalid">-->
      <!--        Save-->
      <!--      </button>-->
    </div>

    <mat-tab-group>
      <mat-tab label="General">
        <div class="m-3">
          <h6 class="my-2">Basics</h6>
          <mat-card class="my-2">
            <mat-card-content>
              <div class="form-row">
                <div class="form-group col-6">
                  <mat-form-field>
                    <input matInput placeholder="Construction Kit ID" type="text"
                           formControlName="ckId">
                    <mat-error *ngIf="hasError('ckId', 'required')">Value missing!</mat-error>
                  </mat-form-field>
                </div>
                <div class="form-group col-6">
                  <mat-form-field>
                    <input matInput placeholder="Base Type" type="text"
                           formControlName="baseType">
                  </mat-form-field>
                </div>
              </div>
              <div class="form-row">
                <div class="form-group col-3">
                  <mat-checkbox formControlName="isAbstract">Abstract</mat-checkbox>
                </div>
                <div class="form-group col-3">
                  <mat-checkbox formControlName="isFinal">Final</mat-checkbox>
                </div>
              </div>
              <div class="form-row">

                <div class="form-group col-6">
                  <mat-form-field>
                    <mat-select placeholder="Scope" formControlName="scopeId">
                      <mat-option *ngFor="let selectValue of scopeSelectionValues" [value]="selectValue.id">
                        {{selectValue.name}}
                      </mat-option>
                    </mat-select>
                    <mat-error *ngIf="hasError('scopeId', 'required')">Value missing!</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-tab>
      <mat-tab label="Derived Types">
        <div class="m-3">
          <app-ck-entities-details-derived-types [constructionKitTypeDetail]="constructionKitTypeDetail"></app-ck-entities-details-derived-types>
        </div>
      </mat-tab>
      <mat-tab label="Attributes">
        <div class="m-3">
          <app-ck-entity-attributes [constructionKitTypeDetail]="constructionKitTypeDetail"></app-ck-entity-attributes>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>
</div>
