import {of} from "rxjs";
import {catchError} from "rxjs/operators";
import {CkEntityDto} from "../models/ckEntityDto";
import {DataSourceBackendService} from "../services/dataSourceBackendService";
import {SearchFilter, Sort, SortOrders} from "../graphQL/globalTypes";
import {DataSourceBase, MessageService, PagedResultDto} from "@ianitor/shared-services";

export class ConstructionKitTypeDataSource extends DataSourceBase<CkEntityDto> {

  constructor(private dataSourceBackendService: DataSourceBackendService, private messageService: MessageService) {
    super();
  }

  loadData(dataSource: string, skip: number = 0, take: number = 10, searchFilter: SearchFilter = null, sort: Sort[] = null) {
    super.onBeginLoad();

    if (!sort || sort && sort.length === 0) {
      sort = new Array<Sort>();
      sort.push(<Sort>{
        attributeName: 'ckId',
        sortOrder: SortOrders.ASCENDING
      });
    }

    this.dataSourceBackendService.getCkEntities(dataSource, skip, take, null, searchFilter, sort).pipe(
      catchError((error) => {

        this.messageService.showError(error, "Error during load of data");

        return of(new PagedResultDto<CkEntityDto>())
      }))
      .subscribe(pagedResult => {

        super.onCompleteLoad(pagedResult);
      });
  }
}
