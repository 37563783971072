<body>
  <mat-sidenav-container>
    <mat-sidenav #sidenav role="navigation">
      <app-sidenav-list (sidenavClose)="sidenav.close()"></app-sidenav-list>
    </mat-sidenav>
    <mat-sidenav-content fxLayout="column">
      <app-header (sidenavToggle)="sidenav.toggle()"></app-header>
      <ia-notification-bar></ia-notification-bar>
      <main>
        <router-outlet></router-outlet>
      </main>
      <app-footer fxFlexOffset="auto"></app-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</body>
