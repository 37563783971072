<div class="loading-indicator" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div fxLayout="column" fxLayoutGap="12px" style="padding-top: 10px">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end">

    <div fxLayout="row" fxFlex="15" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button fxFlex mat-raised-button type="button" (click)="save()"
              [disabled]="ownerForm.invalid">
        Save
      </button>
    </div>
  </div>
</div>


<form [formGroup]="ownerForm" novalidate>
  <div class="form-row">
    <div class="form-group col-6">
      <mat-form-field>
        <input matInput placeholder="Name*" type="text" formControlName="name">
        <mat-error *ngIf="hasError('name', 'required')">Enter value!</mat-error>
      </mat-form-field>
    </div>
  </div>

</form>

