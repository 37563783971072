<mat-nav-list>
  <a mat-list-item routerLink="" (click)="onSidenavClose()">
    <mat-icon>home</mat-icon>
    <span class="nav-caption">Home</span>
  </a>
  <mat-divider></mat-divider>
  <h3 matSubheader><mat-icon matListIcon>code</mat-icon> <span class="nav-caption">Development</span></h3>
  <a mat-menu-item href="{{configurationService.config.jobServices}}swagger">Swagger (JobServices)</a>
  <a mat-menu-item href="{{configurationService.config.coreServices}}swagger" >Swagger (CoreServices)</a>
  <a mat-menu-item href="{{configurationService.config.issuer}}swagger" >Swagger (IdentityServices)</a>
  <mat-divider></mat-divider>
  <button mat-menu-item routerLink="/identity/userDiagnostics">User Diagnostics</button>
  <mat-divider></mat-divider>
  <a mat-list-item (click)="login()" *ngIf="!(isAuthenticated | async)">
    <mat-icon>assignment_ind</mat-icon>
    <span class="nav-caption">Login</span>
  </a>
  <a mat-list-item (click)="logout()" *ngIf="(isAuthenticated | async)">
    <mat-icon>account_balance</mat-icon>
    <span class="nav-caption">Logout</span>
  </a>
</mat-nav-list>
