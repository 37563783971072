import { Component, OnInit } from '@angular/core';
import {DataSourceDataSource} from "../../shared/dataSourceDataSource";
import {CoreBackendServices} from "../../services/core-backend.service";
import {ConfigurationService} from "../../services/configuration.service";
import {ConfirmationService} from "@ianitor/shared-ui";
import {DataSourceDto} from "../../models/dataSourceDto";
import {MessageService} from "@ianitor/shared-services";

@Component({
  selector: 'app-data-sources',
  templateUrl: './data-sources.component.html',
  styleUrls: ['./data-sources.component.css']
})
export class DataSourcesComponent implements OnInit {

  dataSource: DataSourceDataSource;
  isMobile: boolean;
  dataColumns: string[] = ['dataSource', 'database'];
  displayedColumns: string[] = [...this.dataColumns, 'actions'];

  constructor(private coreBackendServices: CoreBackendServices, public configurationService: ConfigurationService,
              private messageService : MessageService,
              private confirmationService: ConfirmationService) { }

  ngOnInit() {

    this.dataSource = new DataSourceDataSource(this.coreBackendServices, this.messageService);
    this.dataSource.loadData();
  }

  onDetach(dataSourceDto: DataSourceDto){
    this.confirmationService.showYesNoConfirmationDialog("Detach Data Source", `Do you really want to detach data source with name '${dataSourceDto.dataSource}'?`).subscribe(confirmResult => {

      if (confirmResult) {

        this.coreBackendServices.detachDataSource(dataSourceDto.dataSource).subscribe(_ => {

          this.messageService.showInformation("Data source detached.");

          this.dataSource.loadData();

        }, error => {

          this.messageService.showError(error, "Error during detaching data source.");
        })
      }
    });
  }

  onDelete(dataSourceDto: DataSourceDto){

    this.confirmationService.showYesNoConfirmationDialog("Delete Data Source", `Do you really want to delete data source with name '${dataSourceDto.dataSource}'?`).subscribe(confirmResult => {

      if (confirmResult) {

        this.coreBackendServices.deleteDataSource(dataSourceDto.dataSource).subscribe(_ => {

          this.messageService.showInformation("Data source deleted.");

          this.dataSource.loadData();

        }, error => {

          this.messageService.showError(error, "Error during deleting data source.");
        })
      }
    });
  }
}
