<mat-divider></mat-divider>
<mat-toolbar>
  <div class="mat-small">
    SALZBURGDEV.AT - Gerald Lochner - Firmianstraße 31A - 5020 Salzburg - Österreich
  </div>

  <div id="ospDashboardVersion" fxFlex fxLayout fxLayoutAlign="end" fxHide.xs>
    <div class="mat-small">
      Version {{version.version}}
    </div>
  </div>
</mat-toolbar>
