<div class="loading-indicator" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div *ngIf="apiError"
     class="alert alert-danger">

  <ul type="disc">
    <li *ngFor="let detail of apiError.details">{{detail.description}}</li>
  </ul>

</div>

<div *ngIf="isLoaded">

  <div fxLayout="column" fxLayoutGap="12px" style="padding-top: 10px">
    <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end">

      <div fxLayout="row" fxFlex="15" fxLayoutAlign="start center" fxLayoutGap="8px">
        <button fxFlex mat-raised-button type="button" (click)="save()"
                [disabled]="ownerForm.invalid">
          Save
        </button>
      </div>
    </div>
  </div>


  <form [formGroup]="ownerForm" novalidate>

    <div class="form-row">
      <div class="form-group col-6">
        <mat-form-field>
          <input matInput placeholder="User ID" type="text" formControlName="userName">
        </mat-form-field>
      </div>
    </div>

    <div class="form-row">
      <div class="form-group col-6">
        <mat-form-field>
          <input matInput placeholder="Password" type="text" formControlName="password">
          <mat-error *ngIf="hasError('password', 'required')">Enter value!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-6">
        <mat-form-field>
          <input matInput placeholder="Password confirmation" type="text" formControlName="passwordConfirmation">
          <mat-error *ngIf="hasError('passwordConfirmation', 'required')">Enter value!</mat-error>
          <mat-error *ngIf="hasError('passwordConfirmation', 'notSame')">Password missmatch</mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</div>
