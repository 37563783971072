import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';

import {DataSourceDto} from '../models/dataSourceDto';

import {Observable} from 'rxjs';
import {ConfigurationService} from "./configuration.service";
import {map} from "rxjs/operators";
import {PagedResultDto} from "@ianitor/shared-services";
import {ExportModelResponseDto} from "../models/exportModelResponseDto";

@Injectable({
  providedIn: 'root'
})
export class CoreBackendServices {

  constructor(private httpClient: HttpClient, private configurationService: ConfigurationService) {
  }

  getDataSources(skip: number, take: number): Observable<PagedResultDto<DataSourceDto>> {

    const params = new HttpParams()
      .set('skip', "" + skip)
      .set('take', "" + take);

    return this.httpClient.get<PagedResultDto<DataSourceDto>>(this.configurationService.config.coreServices + 'system/v1/dataSources', {
      params: params,
      observe: 'response'
    }).pipe(
      map(res => {
        return res.body;
      }),
    );
  }

  getDataSourceDetails(dataSourceId: string): Observable<DataSourceDto> {

    return this.httpClient.get<DataSourceDto>(this.configurationService.config.coreServices + `system/v1/dataSources/${dataSourceId}`, {
      observe: 'response'
    }).pipe(
      map(res => {
        return res.body;
      }),
    );
  }

  createDataSource(dataSourceDto: DataSourceDto) : Observable<any>{

    const params = new HttpParams()
      .set('dataSource', dataSourceDto.dataSource)
      .set('databaseName', dataSourceDto.database);

    return this.httpClient.post<any>(this.configurationService.config.coreServices + 'system/v1/dataSources', null, {
      params: params,
      observe: 'response'
    });
  }

  attachDataSource(dataSourceDto: DataSourceDto) : Observable<any>{

    const params = new HttpParams()
      .set('dataSource', dataSourceDto.dataSource)
      .set('databaseName', dataSourceDto.database);

    return this.httpClient.post<any>(this.configurationService.config.coreServices + 'system/v1/dataSources/attach', null, {
      params: params,
      observe: 'response'
    });
  }

  detachDataSource(dataSourceId: string) : Observable<any>{

    const params = new HttpParams()
      .set('dataSource', dataSourceId);

    return this.httpClient.post<any>(this.configurationService.config.coreServices + 'system/v1/dataSources/detach', null, {
      params: params,
      observe: 'response'
    });
  }

  deleteDataSource(dataSourceId: string) : Observable<any>{

    const params = new HttpParams()
      .set('dataSource', dataSourceId);

    return this.httpClient.delete<any>(this.configurationService.config.coreServices + 'system/v1/dataSources', {
      params: params,
      observe: 'response'
    });
  }

  exportRtModel(dataSource: string, queryId: string) : Observable<string>{

    const params = new HttpParams()
      .set('dataSource', dataSource);

    return this.httpClient.post<ExportModelResponseDto>(this.configurationService.config.coreServices + 'system/v1/Models/ExportRt', {queryId: queryId}, {
      params: params,
      observe: 'response'
    }).pipe(
      map(res => {
        return res.body.jobId;
      }),
    );
  }
}
