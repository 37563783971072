import {of} from "rxjs";
import {catchError} from "rxjs/operators";
import {DataSourceDto} from "../models/dataSourceDto";
import {CoreBackendServices} from "../services/core-backend.service";
import {DataSourceBase, MessageService, PagedResultDto} from "@ianitor/shared-services";

export class DataSourceDataSource extends DataSourceBase<DataSourceDto>{

  constructor(private coreBackendServices: CoreBackendServices, private messageService : MessageService) {
    super()
  }

  loadData(skip: number = 0, take: number = 10) {
    super.onBeginLoad();

    this.coreBackendServices.getDataSources(skip, take).pipe(
      catchError((error) => {

        this.messageService.showError(error, "Error during load of data");

        return of(new PagedResultDto<DataSourceDto>())
      }))
      .subscribe(pagedResult => {

        super.onCompleteLoad(pagedResult);
      });
  }
}
