<div class="loading-indicator" *ngIf="loading">
  <mat-spinner></mat-spinner>
</div>

<div fxLayout="column" fxLayoutGap="12px" style="padding-top: 10px">
  <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="end">

    <div fxLayout="row" fxFlex="15" fxLayoutAlign="start center" fxLayoutGap="8px">
      <button fxFlex mat-raised-button type="button" (click)="save()"
              [disabled]="dataSourceForm.invalid">
        Save
      </button>
    </div>
  </div>
</div>


<form [formGroup]="dataSourceForm" novalidate>
  <mat-form-field>
    <input matInput placeholder="Datasource name" type="text" formControlName="dataSource">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Database name" type="text" formControlName="database">
  </mat-form-field>
</form>
